import BasicModal from '@components/_atoms/Modal/Basic';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import CheckBox from '@components/_molecules/CheckBox';
import { useEffect, useState } from 'react';
import { getCookie, removeCookie, setCookie } from '@utils/cookie';
import { DAY } from '@constants/time';

type EventModalProps = {
  title: string;
  enTitle: string;
  imageUrl: string;
  enImageUrl: string;
  height: number;
  width: number;
  hidden_days: number;
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
};

export default function EventModal({
  title,
  enTitle,
  imageUrl,
  enImageUrl,
  height,
  width,
  hidden_days,
  isOpen,
  onClose,
  eventId,
}: EventModalProps) {
  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;
  const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  }, []);

  const closeModal = () => {
    const eventCookie = `EVENT_${eventId}`;

    if (doNotShowStatusCheck) {
      setCookie(eventCookie, 'true', { maxAge: hidden_days * DAY, domain: currentDomain, path: '/' });
    } else {
      const eventCookieValue = getCookie(eventCookie);
      if (eventCookieValue) {
        removeCookie(eventCookie, { domain: currentDomain, path: '/' });
      }
    }
    setDoNotShowStatusCheck(!doNotShowStatusCheck);
    onClose();
  };

  const doNotShowMessage = ko
    ? hidden_days == 1
      ? '오늘 하루 보지 않기'
      : `${hidden_days}일 간 보지 않기`
    : hidden_days == 1
    ? 'Do not show today'
    : `Do not show for ${hidden_days} days`;
  const [doNotShowStatusCheck, setDoNotShowStatusCheck] = useState(false);

  return (
    <>
      {isOpen && (
        <BasicModal
          isOpen={isOpen}
          onClose={closeModal}
          title={ko ? title : enTitle}
          isCancelButton={false}
          ok={closeModal}>
          <Image
            alt="event"
            src={`${cdnUrl}/common/event/${ko ? imageUrl : enImageUrl}`}
            width={width}
            height={height}
            objectFit="fill"
          />
          <CheckBox
            title={doNotShowMessage}
            checkStatus={doNotShowStatusCheck}
            onClick={() => setDoNotShowStatusCheck(!doNotShowStatusCheck)}
          />
        </BasicModal>
      )}
    </>
  );
}
