import styled from '@emotion/styled';
import Link from 'next/link';
import { useIsMobile } from '@hooks/responsive/useIsMobile';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

// components
import Typography from '@components/_atoms/Typography';
import Button from '@components/_atoms/Button';

// constants
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { TypoVariant } from '@constants/atoms';
import { blue, primary, white } from '@styles/Colors';
import { BUYER, LADING_IMAGE_FILE, LANDING_VIDEO_FILE_WEBM, SELLER } from '@constants/routes/routes';

// Lottie animation
const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});
import ArrowLottie from '@public/assets/lottie/arrow.json';
import ButtonCode from '@constants/log/buttonCode';
import { getCookie } from '@utils/cookie';
import { useEffect, useState } from 'react';
import EventModal from '../event';

type Props = {
  handleArrowClick: () => void;
};

const Layout = styled.section`
  width: 100%;
  width: 100dvw;
  /* min-height: 724px; */
  height: 100vh;
  height: 80dvh;
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    min-height: -webkit-fill-available;
  }
  background: var(--black-10, rgba(0, 0, 0, 0.3));
  position: relative;
  display: flex;
  align-items: center;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  width: 100dvw;
  height: 100%;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  overflow: hidden;
`;

const BackgroundVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1160px;
  padding: 0 160px;

  @media ${TABLET_MODE} {
    width: 768px;
    padding: 0 40px;
  }

  @media ${MOBILE_MODE} {
    width: 100%;
    padding: 0 40px;
  }
`;

const DescriptionBox = styled.div`
  display: grid;
  row-gap: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media ${MOBILE_MODE} {
    flex-direction: column;
    row-gap: 12px;
  }
`;

const ButtonWrapper = styled.button<{ backgroundColor: string; hoverBackgroundColor: string }>`
  display: flex;
  padding: 20px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 640px;

  ${({ backgroundColor, hoverBackgroundColor }) => `
    background-color: ${backgroundColor};

    &:hover {
      background-color: ${hoverBackgroundColor};
    }
  `}

  @media ${MOBILE_MODE} {
    width: 100%;
    gap: 0px;
    padding: 12px 20px;
  }
  @media ${TABLET_MODE} {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 18px;
  font-weight: 500;

  @media ${MOBILE_MODE} {
    font-size: 16px;
  }
`;

const ArrowLottieWrapper = styled.div`
  cursor: pointer;

  width: 96px;
  height: 96px;

  position: absolute;
  top: 87.5%;
  left: 50%;
  transform: translateX(-50%);
`;

export default function MainSection({ handleArrowClick }: Props) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('home');
  const { i18n } = useTranslation();
  const ko = i18n.language === 'ko';

  const eventCookie = `EVENT_20250325`;
  const eventCookieValue = getCookie(eventCookie);

  const [isOpenEventModal, setIsOpenEventModal] = useState(false);

  useEffect(() => {
    if (!eventCookieValue) {
      setIsOpenEventModal(!eventCookieValue);
    }
  }, [eventCookieValue]);

  return (
    <Layout>
      <BackgroundWrapper>
        <BackgroundVideo autoPlay loop muted playsInline poster={LADING_IMAGE_FILE}>
          <source src={LANDING_VIDEO_FILE_WEBM} type="video/webm" />
        </BackgroundVideo>
      </BackgroundWrapper>

      <Content>
        <DescriptionBox>
          <Typography variant={isMobile ? TypoVariant.H5 : TypoVariant.H3} color={white}>
            {t('main.title')}
          </Typography>
          <Title>{t('main.subTitle')}</Title>
        </DescriptionBox>

        <ButtonContainer>
          <Link href={BUYER}>
            <ButtonWrapper backgroundColor={primary.blue} hoverBackgroundColor={blue.blue4}>
              <Typography variant={isMobile ? TypoVariant.SH2 : TypoVariant.H6} color={white}>
                {t('main.button1')}
              </Typography>
              <Typography variant={isMobile ? TypoVariant.SH3 : TypoVariant.SH2} color={white}>
                {t('main.button1-content')}
              </Typography>
            </ButtonWrapper>
          </Link>
          <Link href={SELLER}>
            <ButtonWrapper backgroundColor={blue.blue1} hoverBackgroundColor={blue.blue2}>
              <Typography variant={isMobile ? TypoVariant.SH2 : TypoVariant.H6} color={primary.gray}>
                {t('main.button2')}
              </Typography>
              <Typography variant={isMobile ? TypoVariant.SH3 : TypoVariant.SH2} color={primary.gray}>
                {t('main.button2-content')}
              </Typography>
            </ButtonWrapper>
          </Link>
        </ButtonContainer>
      </Content>

      <ArrowLottieWrapper onClick={handleArrowClick}>
        <Lottie animationData={ArrowLottie} />
      </ArrowLottieWrapper>

      {isOpenEventModal && (
        <EventModal
          title={'필리핀 법인 설립'}
          enTitle={'Steelboso Unveils Southeast Asia Hub in the Philippines!'}
          imageUrl={'popup_philipine_office_v3.png'}
          enImageUrl={'popup_philipine_office_en_v3.png'}
          width={ko ? 807 : 727}
          height={ko ? 500 : 630}
          hidden_days={1}
          isOpen={isOpenEventModal}
          eventId={'20250325'}
          onClose={() => setIsOpenEventModal(false)}
        />
      )}
    </Layout>
  );
}
