// colors
import { primary, gray, white } from '@styles/Colors';
import styled from '@emotion/styled';

// component
import Typography from '@components/_atoms/Typography';
import Icon from '@components/_atoms/Icon';

// constant
import { IconSize, TypoVariant } from '@constants/atoms';
import { useMemo } from 'react';

type Props = {
  readonly title?: React.ReactNode;
  readonly checkStatus?: boolean;
  readonly onClick?: () => void;
};

const CheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
`;

const CheckBoxIcon = styled(Icon)``;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default function CheckBox({ title, checkStatus, onClick }: Props) {
  const text = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <Typography variant={TypoVariant.B4} color={gray.gray6}>
          {title}
        </Typography>
      );
    } else {
      return title;
    }
  }, [title]);

  return (
    <CheckBoxWrapper>
      <>
        <CheckBoxIcon
          name={checkStatus ? 'check-square-blue' : 'check-square-empty'}
          fill={checkStatus ? primary.blue : white}
          stroke={checkStatus ? white : gray.gray6}
          onClick={onClick}
        />
      </>
      <>
        <TextWrapper>{text}</TextWrapper>
      </>
    </CheckBoxWrapper>
  );
}
